<template>
  <ShareLandingCom :musicOrVideoShow="false" ref="ShareLandingCom">
    <template slot="control">
      <!--       -->
      <div class="video-control">
        <video
          v-if="pageInfo.mv_url"
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-orientation="portraint"
          x5-video-player-fullscreen="true"
          webkit-playsinline
          x-webkit-playsinline
          x5-playsinline
          playsinline
          id="video"
          class="video"
          :poster="pageInfo.cover"
          loop
        >
          <source :src="pageInfo.mv_url" type="video/mp4" />
        </video>
        <div v-else class="empty">
          <img class="empty-icon" src="@/assets/images/common/empty-icon.png" alt="icon" />
          <div class="empty-info">作品走丢了…</div>
        </div>

        <div
          @click.self="openApp('#video-control-content')"
          :data-clipboard-text="pageInfo.user_info.invite_code"
          id="video-control-content"
          class="video-control-content"
        >
          <div v-if="pageInfo.mv_url" @click.prevent="changePlayVideo" class="control-warp">
            <template v-if="!isVideoPlay">
              <div class="control-warp-bg"></div>
              <img v-if="!isVideoPlay" class="video-dire" src="@/assets/images/share-content/stop.png" alt="快音" />
              <img
                v-if="isVideoPlay"
                :class="['video-dire', isVideoPlay ? '' : '']"
                src="@/assets/images/share-content/start.png"
                alt="快音"
              />
            </template>
          </div>
          <div class="discuss-like-warp">
            <div @click.prevent="clickLike" class="discuss-like-warp-item">
              <img
                class="right_img like_img"
                :src="
                  isLikeBool
                    ? require('@/assets/images/share-content/like.png')
                    : require('@/assets/images/share-content/dislike.png')
                "
                alt="快音"
              />
              <span>{{ accessNum }}</span>
            </div>
            <div
              :data-clipboard-text="pageInfo.user_info.invite_code"
              @click.prevent="openApp('#more-like-warp', 'more')"
              id="more-like-warp"
              class="discuss-like-warp-item"
            >
              <img class="right_img" src="@/assets/images/share-content/logo.png" alt="快音" />
              <span>做同款</span>
            </div>
            <!--            <div class="discuss-like-warp-item">更多</div>-->
          </div>
          <div class="aviator-focus-wrap">
            <div
              :data-clipboard-text="pageInfo.user_info.invite_code"
              @click.prevent="openApp('#aviator-focus')"
              id="aviator-focus"
              class="aviator-focus-warp-top"
            >
              <div class="aviator-focus-warp-aviator">
                <img class="aviator-warp-img__aviator" :src="pageInfo.user_info.avatar_small" alt="" />
                <span class="aviator-warp-focus">{{ pageInfo.user_info.nickname }}</span>
              </div>
            </div>
            <div
              @click.prevent="openApp('#video_name')"
              id="video_name"
              :data-clipboard-text="pageInfo.user_info.invite_code"
              class="video_name"
            >
              <div class="video_name-main">{{ pageInfo.name }}</div>
              <div class="video_name-sub">{{ pageInfo.sub_title }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ShareLandingCom>
</template>

<script>
  import ShareLandingCom from './ShareLandingCom';
  import API from '@/api';
  import { mapState } from 'vuex';

  export default {
    props: {},
    components: { ShareLandingCom },
    data() {
      return {
        isVideoPlay: false,
        isLikeBool: false,
        accessNum: 0,
      };
    },
    computed: {
      ...mapState('share/compositeVideo', ['pageInfo']),
    },

    async asyncData({ store, $http, params }) {
      let { compose_video_work_id } = params;

      try {
        const data = await $http.post(API.GET_VIDEO_SYNTH_PAGE_INFO, {
          compose_video_work_id,
        });
        store.commit('share/compositeVideo/SET_PAGE_INFO', data);
      } catch (e) {
        console.log(e);
      }
    },
    beforeMount() {
      this.$track({ page_title: '视频合成分享页', element_name: '页面曝光' });

      let accessNum = localStorage.getItem('accessNum');
      accessNum && (this.accessNum = accessNum);

      this.accessNum++;
      localStorage.setItem('accessNum', this.accessNum);
      let isLikeBool = JSON.parse(localStorage.getItem('isLikeBool'));
      this.$nextTick(() => {
        isLikeBool && (this.isLikeBool = isLikeBool);
      });
    },
    beforeDestroy() {},
    methods: {
      clickLike(e) {
        e.stopPropagation();
        e.preventDefault();
        this.isLikeBool = !this.isLikeBool;
        localStorage.setItem('isLikeBool', this.isLikeBool);

        if (this.isLikeBool) {
          this.accessNum++;
        } else {
          this.accessNum--;
        }
        localStorage.setItem('accessNum', this.accessNum);
      },
      /**
       * @description @改变播放状态
       * @return @void
       */

      changePlayVideo(e) {
        e.stopPropagation();
        e.preventDefault();
        const video = document.getElementById('video');

        if (video.paused) {
          this.playVideo(video);
        } else {
          this.pauseVideo(video);
        }
      },
      playVideo(video, flag = true) {
        video.play();
        this.isVideoPlay = flag;
      },
      pauseVideo(video) {
        video.pause();
        this.isVideoPlay = false;
      },
      /**
       * @description 方法名
       * @param {string} origin 元素名
       * @param {string} element_id 元素id
       */

      openApp(element_id, origin) {
        if (origin && origin === 'more') {
          this.$track({ page_title: '视频合成分享页', element_name: '点击制作同款' });
        }
        this.$refs.ShareLandingCom.openApp(element_id);
      },
    },
  };
</script>

<style lang="less" scoped>
  .video-control {
    position: relative;
    .video {
      height: calc(100vh - 1rem);
      width: 100%;
      object-fit: cover; /* 这里是关键 */
    }
    .empty {
      height: calc(100vh - 1rem);
      width: 100%;
      background: linear-gradient(180deg, rgba(42, 44, 45, 0) 0%, rgba(42, 44, 45, 0.8) 100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      &-icon {
        width: 2.5rem;
        height: 2.5rem;
        margin-top: 2rem;
      }
      &-info {
        color: #a6a6a6;
        font-size: 0.28rem;
      }
    }
    .video-control-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .control-warp {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .control-warp-bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #000;
          border-radius: 50%;
          opacity: 0.5;
        }
        .video-dire {
          z-index: 90;
          width: 1.2rem;
          height: 1.2rem;
        }
      }
      .discuss-like-warp {
        position: absolute;
        right: 0.4rem;
        bottom: 1.6rem;
        font-size: 0.24rem;
        .discuss-like-warp-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: rgb(255, 255, 255);
          margin-top: 0.2rem;
          font-size: 0.24rem;
          span {
            font-size: 0.24rem;
            text-align: center;
          }

          .right_img {
            width: 0.48rem;
            height: 0.48rem;
          }
        }
      }
      .aviator-focus-wrap {
        position: absolute;
        bottom: 0.6rem;
        left: 0.3rem;
        right: 1rem;
        color: #fff;
        .aviator-focus-warp-top {
          display: flex;
          align-items: center;
          .aviator-focus-warp-aviator {
            display: flex;
            align-items: center;

            color: #fff;
            .aviator-warp-img__aviator {
              width: 0.62rem;
              height: 0.62rem;
              z-index: 1;
              border-radius: 50%;
              margin-left: 0.04rem;
            }
            .aviator-warp-img__add {
              width: 0.2rem;
              height: 0.2rem;
              z-index: 1;
            }
            .aviator-warp-focus {
              margin-left: 0.2rem;
              z-index: 1;
              font-size: 0.3rem;
              width: 3.3rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: bold;
            }
          }
        }
        .video_name {
          font-size: 0.32rem;
          margin-top: 0.2rem;
          font-weight: 600;
          &-main {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &-sub {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.26rem;
          }
        }
      }
    }

    @keyframes moveDis {
      0% {
        right: -3.4rem;
      }
      100% {
        right: 8rem;
      }
    }
    @keyframes breathing {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
</style>
