<template>
  <div>
    <div class="share-landing-com-container">
      <!-- 顶部置顶组件 -->
      <div class="share-landing-com-top-warp">
        <img class="top-warp-img__logo" :src="pageInfo.user_info.avatar_small" alt="快音" />
        <div class="top-warp-content">
          <div class="top-warp-logo-name">{{ pageInfo.user_info.nickname }}</div>
          <div class="top-warp-invite-info">邀请你来<span class="name">快音</span>听歌看视频</div>
        </div>
        <div
          id="com-button-open-app"
          :data-clipboard-text="pageInfo.user_info.invite_code"
          @click="openApp('#com-button-open-app', 'open')"
          class="top-warp-open-btn"
        >
          打开App
        </div>
      </div>
      <!-- 顶部置顶组件  结束-->
      <div class="bg_content">
        <!-- 控件插槽位置 -->
        <slot name="control"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import Clipboard from 'clipboard';
  import Download from '@/utils/download';
  import { mapState } from 'vuex';
  import InitUmLink from '@/utils/init-ulink';

  export default {
    data() {
      return {
        umLink: null,
      };
    },
    computed: {
      ...mapState('share/compositeVideo', ['pageInfo']),
    },
    mounted() {
      // 初始化友盟

      if (process.env.NODE_ENV !== 'development') {
        this.initUm();
      }
    },
    methods: {
      /**
       * @description 下载APP
       */

      openApp(element_id, origin) {
        if (origin && origin === 'open') {
          this.$track({ page_title: '视频合成分享页', element_name: '点击打开APP' });
        }
        this.$toast({
          message: '亲~正在为你拉起下载页',
          duration: 5000,
          overlay: false,
        });
        // 使用友盟 U-link
        if (process.env.NODE_ENV !== 'development' && this.umLink.isUmLinkInitSuccess) {
          return;
        }
        try {
          const clipboard = new Clipboard(element_id);

          let jump = () => {
            let url;

            if (this.$platform.Android) {
              url = Download.downLoad('yyb');
            } else if (this.$platform.iOS) {
              url = Download.downLoad('share_ios');
            } else {
              url = Download.downLoad('yyb');
            }
            window.location.href = url;
          };

          // 无邀请码时，无法 catch 到错误
          if (!this.pageInfo.user_info.invite_code) {
            jump();
            return;
          }

          clipboard.on('success', (e) => {
            e.clearSelection();

            jump();
          });
          clipboard.on('error', () => {
            jump();
          });
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      initUm() {
        let selectorStr = '#com-button-open-app,#video-control-content,#more-like-warp,#aviator-focus,#video_name';
        this.umLink = new InitUmLink({
          selector: selectorStr,
          linkId: 'usr1lus733n7pasq',
          pageTitle: '视频合成落地页',
          clipboardText: this.pageInfo.user_info.invite_code,
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .share-landing-com-container {
    overflow: auto;
    // min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .bg_content {
    padding-top: 1rem;
    background-size: 100% 100%;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
  }

  .share-landing-com-top-warp {
    display: flex;
    position: fixed;
    color: #fff;
    z-index: 91;
    background: #000;
    top: 0;
    left: 0;
    right: 0;
    height: 1rem;
    align-items: center;
    padding: 0 0.4rem;
    .top-warp-content {
      flex: 1;
      .top-warp-logo-name {
        font-size: 0.3rem;
        font-weight: bold;
      }
      .top-warp-invite-info {
        font-size: 0.26rem;
        font-weight: 400;
        color: #fff;
        .name {
          margin: 0 0.1rem;
          color: #ff2b3d;
        }
      }
    }
    .top-warp-img__logo {
      width: 0.72rem;
      height: 0.72rem;
      border-radius: 50%;
      margin-right: 0.2rem;
    }
    .top-warp-open-btn {
      width: 1.4rem;
      height: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
      font-weight: bold;
      color: #ffffff;
      line-height: 0.24rem;
      background: #ff2b3d;
    }
  }
</style>
